import { createFileRoute, useNavigate } from "@tanstack/react-router";
import useAuthentication from "../hooks/useAuthentication";
import { useEffect } from "react";

export const Route = createFileRoute("/domain")({
  validateSearch: (search: any) => ({
    redirect: search.redirect as string,
    token: search.token as string,
  }),
  component: RouteComponent,
});

function RouteComponent() {
  const { organization, isAuthenticated, isLoading } = useAuthentication();
  const search = Route.useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    // Only proceed with navigation if we have an organization
    if (!organization?.id) return;

    // Handle token-based navigation first (highest priority)
    if (search.token) {
      navigate({
        to: `/auth/invite`,
        search: { token: search.token },
        replace: true,
      });
      return;
    }

    // Handle authentication-based navigation
    const targetPath =
      (isAuthenticated && search.redirect) ||
      (isAuthenticated ? "/" : "/auth/login");
    navigate({ to: targetPath, replace: true });
  }, [organization, isAuthenticated, isLoading, search, navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-svh gap-4">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="text-center">
          <h2 className="text-xl font-medium mb-2">Invalid Domain</h2>
          <p>Please use a valid subdomain to access the system.</p>
        </div>
      )}
    </div>
  );
}
