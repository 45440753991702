import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  // Enable loading translations from backend
  .use(Backend)
  // Pass the i18n instance to react-i18next
  .use(initReactI18next)
  // Initialize i18next
  .init({
    // eslint-disable-next-line turbo/no-undeclared-env-vars
    debug: import.meta.env.DEV,
    fallbackLng: "en",
    defaultNS: "common",
    fallbackNS: "common",
    load: "languageOnly",
    ns: ["common", "import"],
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
    backend: {
      // Path where language resources are loaded from
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      // Order of language detection methods
      order: ["querystring", "cookie", "localStorage", "navigator"],
      // Cache user language on
      caches: ["localStorage", "cookie"],
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
