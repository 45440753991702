import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAuthenticationStore } from "./hooks/useAuthentication";
import { attachAuthInterceptor } from "./utils/attachAuthInterceptor";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: "always",
      staleTime: 0,
    },
  },
});

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || "http://localhost:5000", // Use environment variable or fallback
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus(status) {
    return status >= 200 && status <= 400;
  },
});

attachAuthInterceptor(api, {
  getAccessToken: function (): string | null {
    return useAuthenticationStore.getState().accessToken;
  },
  setAccessToken: function (accessToken, refreshToken): void {
    useAuthenticationStore.getState().setToken(accessToken, refreshToken);
  },
  refreshToken: async function () {
    const res = await api.post("/v1/auth/refresh", {
      token: useAuthenticationStore.getState().refreshToken,
    });

    if (res.status !== 200) {
      throw new Error("Failed to refresh token");
    }
    return {
      accessToken: res.data.data.accessToken,
      refreshToken: res.data.data.refreshToken,
    };
  },
  onRefreshFail: (err) => {
    console.error("Refresh token failed", err);
    // Optional: redirect to login page, clear session, etc.
    useAuthenticationStore.getState().logout();
  },
});
