import StaffsPage from "@ella/web/modules/staffs";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/staffs")({
  component: RouteComponent,
});

function RouteComponent() {
  return <StaffsPage />;
}
