import { create } from "zustand";

interface AccountStore {
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  update(data: Partial<AccountStore>): void;
}

export const useAccountStore = create<AccountStore>((set) => {
  return {
    email: "",
    firstName: "",
    lastName: "",
    token: "",
    update: (data) => set(data),
  };
});
