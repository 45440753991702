import { createFileRoute, redirect } from "@tanstack/react-router";
import { verifyInviteToken } from "@ella/web/modules/auth";
import useToast from "@ella/web/hooks/useToast";
import { useEffect } from "react";
type InviteSearch = {
  token: string;
};

export const Route = createFileRoute("/auth/invite")({
  component: RouteComponent,
  beforeLoad: ({ context, search }) => {
    if (!context.auth.organizationId) {
      throw redirect({
        to: "/domain",
        search: {
          token: search.token,
        },
        replace: true,
      });
    }
  },
  shouldReload: true,
  loaderDeps: ({ search: { token } }) => ({ token }),
  loader: ({ deps: { token }, context }) => {
    return verifyInviteToken(token, context.auth.organizationId.toString());
  },
  validateSearch: (search: Record<string, unknown>): InviteSearch => {
    if (!search.token || typeof search.token !== "string") {
      throw new Error("Invalid token");
    }
    return {
      token: search.token as string,
    };
  },
});

function RouteComponent() {
  const data = Route.useLoaderData();
  const navigate = Route.useNavigate();
  const { toastInfo } = useToast();

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.success) {
      navigate({ to: "/auth/setup", replace: true });
      return;
    }
    if (data?.errors?.length > 0) {
      if (data?.errors?.[0]?.msg === "TOKEN_EXPIRED") {
        navigate({ to: "/auth/invalid-token", replace: true });
        return;
      }
      if (data?.errors?.[0]?.msg === "TOKEN_USED") {
        toastInfo(
          "This account has been activated before. You can continue to log in with your password or Google.",
          { mode: "single", id: "TOKEN_USED" }
        );
        navigate({ to: "/auth/login", replace: true });
        return;
      }
    }
    navigate({ to: "/auth/login", replace: true });
  }, [data, navigate, toastInfo]);

  return <></>;
}
