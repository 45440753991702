import { RouterProvider, createRouter } from "@tanstack/react-router";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import Modal_MG from "@ella/ui/components/modal/index";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./queryClient";
import useAuthentication from "./hooks/useAuthentication";
import { Toaster } from "@ella/ui/components/ui/sonner";
// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    auth: {
      isAuthenticated: false,
      organizationId: "",
      userId: "",
    },
  },
});

function ProtectApp() {
  const { isAuthenticated, organization, user } = useAuthentication();

  return (
    <RouterProvider
      router={router}
      defaultPreload="intent"
      context={{
        auth: {
          isAuthenticated,
          organizationId: organization?.id || "",
          userId: user?.id || "",
        },
      }}
    />
  );
}
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Modal_MG.Provider>
        <ProtectApp />
        <Toaster />
      </Modal_MG.Provider>
    </QueryClientProvider>
  );
}

export default App;
