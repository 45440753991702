import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@ella/ui/lib/utils";

export const btnTextColor = {
  primary:
    "text-text-interactive hover:bg-primary-100 active:bg-primary-200 min-w-[68px]",
  warning: "text-text-warning",
  info: "text-info-foreground",
  success: "text-success-foreground",
  error: "text-error-foreground",
  disabled: "text-disabled",
};

const buttonVariants = cva(
  "inline-flex items-center typo-button justify-center gap-1 whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring-focus disabled:bg-zinc-200 disabled:text-disabled disabled:cursor-not-allowed  [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        text: "text-text-interactive hover:bg-primary-100 active:bg-primary-200 disabled:text-text-disabled px-3 !py-1",
        default:
          "border bg-button hover:bg-button-hover active:bg-button-pressed disabled:bg-button-disabled",
        primary:
          "text-text-on-color bg-surface-interactive hover:bg-surface-interactive-hover active:bg-surface-interactive-pressed disabled:bg-surface-disabled",
        outline:
          " border border-border-outline bg-background hover:bg-button-outline-hover active:bg-button-outline-pressed disabled:bg-background",
        subtle:
          " hover:bg-button-subtle-hover active:bg-button-subtle-pressed disabled:bg-background",
        ghost:
          " bg-background hover:bg-neutral-100 active:bg-neutral-200 disabled:bg-background",
        menuItem:
          "typo-body-sm-400 [&_span]:typo-body-sm-400 hover:bg-neutral-100 active:bg-neutral-200 disabled:bg-background focus-visible:ring-0 flex justify-start relative gap-2",
        danger:
          " bg-button-danger-primary text-text-on-color hover:bg-button-danger-primary-hover active:bg-button-danger-primary-pressed",
        "danger-outlined":
          " border text-text-error border-error-600 bg-background hover:bg-error-50 active:bg-error-100 disabled:bg-background disabled:border-border-outline",
        "danger-subtle":
          " hover:bg-error-100 bg-error-50 text-error-700 active:bg-error-200 disabled:bg-background",
        warning:
          " bg-button-warning-primary text-text-title hover:bg-button-warning-primary-hover active:bg-button-warning-primary-pressed",
        "warning-outlined":
          "border text-warning-700 border-button-warning-secondary-border bg-background hover:bg-button-warning-secondary-hover active:bg-button-warning-secondary-pressed disabled:bg-background disabled:border-border-disabled",
        textError:
          "text-text-error hover:bg-error-50 active:bg-error-100 disabled:text-text-disabled",
        textWarning:
          "text-text-warning hover:bg-warning-50 active:bg-warning-100 disabled:text-text-disabled",
        textSuccess:
          "text-text-success hover:bg-success-50 active:bg-success-100 disabled:text-text-disabled",
        success:
          " bg-success text-text-on-color hover:bg-success-600 active:bg-success-700",
      },
      size: {
        default: "px-3 py-2",
        sm: "h-9 px-3",
        md: "h-9",
        xs: "max-h-8 px-3 py-1",
        icon: "h-10 w-10",
        menu: "h-10 p-2 rounded-md typo-body-sm-400",
        text: "px-3 py-1",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  noContainer?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, noContainer, asChild = false, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {noContainer ? (
          props.children
        ) : (
          <span
            className={cn(
              variant !== "text" && "mx-1",
              "typo-button flex justify-center items-center gap-1"
            )}
          >
            {props.children}
          </span>
        )}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
