import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";

interface MyRouterContext {
  // The ReturnType of your useAuth hook or the value of your AuthContext
  auth: {
    isAuthenticated: boolean;
    organizationId: string | number;
    userId: string | number;
  };
}
export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: App,
  notFoundComponent: () => <div>Not Found1 121</div>,
});

function App() {
  return (
    <>
      <Outlet />
      <TanStackRouterDevtools position="top-right" />
    </>
  );
}
