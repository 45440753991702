import {
  MODAL_HIDE_ACTION,
  MODAL_REMOVE_ACTION,
  MODAL_SET_FLAGS_ACTION,
  MODAL_SHOW_ACTION,
} from "./contants";
import { ModalAction } from "./type";

// action creator to show a modal
export function showModalAction(
  modalId: string,
  args?: Record<string, unknown>,
): ModalAction {
  return {
    type: MODAL_SHOW_ACTION,
    payload: {
      modalId,
      args,
    },
  };
}

// action creator to show a modal
export function hideModalAction(modalId: string): ModalAction {
  return {
    type: MODAL_HIDE_ACTION,
    payload: {
      modalId,
    },
  };
}

// action creator to remove a modal
export function removeModalAction(modalId: string): ModalAction {
  return {
    type: MODAL_REMOVE_ACTION,
    payload: {
      modalId,
    },
  };
}

// action creator to set flags of a modal
export function setModalFlagsAction(
  modalId: string,
  flags: Record<string, unknown>,
): ModalAction {
  return {
    type: MODAL_SET_FLAGS_ACTION,
    payload: {
      modalId,
      flags,
    },
  };
}
