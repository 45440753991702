// attachAuthInterceptor.ts
import type { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";

interface AuthInterceptorOptions {
  getAccessToken: () => string | null;
  setAccessToken: (accessToken: string, refreshToken: string) => void;
  refreshToken: () => Promise<{ accessToken: string; refreshToken: string }>;
  onRefreshFail?: (error: any) => void;
}

export function attachAuthInterceptor(
  api: AxiosInstance,
  options: AuthInterceptorOptions
) {
  let isRefreshing = false;
  let failedQueue: {
    resolve: (token: string) => void;
    reject: (err: any) => void;
  }[] = [];

  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) prom.reject(error);
      else prom.resolve(token!);
    });
    failedQueue = [];
  };

  api.interceptors.request.use((config) => {
    const token = options.getAccessToken();
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  api.interceptors.response.use(
    (res: any) => res,
    async (err: AxiosError) => {
      const originalRequest = err.config as AxiosRequestConfig & {
        _retry?: boolean;
      };

      if (err.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({
              resolve: (token: string) => {
                if (originalRequest.headers)
                  originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(api(originalRequest));
              },
              reject,
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newToken = await options.refreshToken();
          options.setAccessToken(newToken.accessToken, newToken.refreshToken);
          api.defaults.headers.Authorization = `Bearer ${newToken.accessToken}`;
          processQueue(null, newToken.accessToken);
          if (originalRequest.headers)
            originalRequest.headers.Authorization = `Bearer ${newToken.accessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          console.error("Refresh token failed", refreshError);
          processQueue(refreshError, null);
          if (options.onRefreshFail) options.onRefreshFail(refreshError);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(err);
    }
  );
}
