import { useAccountSetup } from "@ella/web/modules/auth/hooks/useAccount";
import {
  createFileRoute,
  Navigate,
  Outlet,
  useBlocker,
} from "@tanstack/react-router";

export const Route = createFileRoute("/auth/_private")({
  component: RouteComponent,
});

function RouteComponent() {
  const { token } = useAccountSetup();

  useBlocker({
    shouldBlockFn: (info) => {
      console.log("shouldBlockFn", info);
      if (
        info.action === "REPLACE" ||
        info.next.routeId.includes("/auth/_private")
      ) {
        return false;
      }
      return !!token;
    },
  });

  if (!token) {
    return <Navigate to="/auth/login" replace={true} />;
  }
  return <Outlet />;
}
