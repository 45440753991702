import { api } from "@ella/web/queryClient";

export const activeWithGoogle = async (
  inviteToken: string,
  idToken: string
) => {
  const response = await api.post(`/v1/auth/invite/active-with-google`, {
    idToken,
    inviteToken,
  });
  return response.data;
};

export const signInWithGoogle = async (
  idToken: string,
  organizationId: number
) => {
  const response = await api.post(`/v1/auth/session/google`, {
    idToken,
    organizationId,
  });
  return response.data;
};
