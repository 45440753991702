/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainLayout } from "@ella/web/layout/main-layout";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.organizationId) {
      throw redirect({
        to: "/domain",
        search: { redirect: location.href } as any,
      });
    }
  },
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
}
