"use client";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  ModalAction,
  ModalStore,
  ModalArgs,
  ModalCallbacks,
  ModalHocProps,
  ModalHandler,
} from "./type";
import {
  MODAL_HIDE_ACTION,
  MODAL_REMOVE_ACTION,
  MODAL_SHOW_ACTION,
} from "./contants";
import {
  hideModalAction,
  removeModalAction,
  setModalFlagsAction,
  showModalAction,
} from "./actions";
import { createPortal } from "react-dom";

const initialState: ModalStore = {};

const MODAL_REGISTRY: {
  [id: string]: {
    comp: React.FC<any>;
    props?: Record<string, unknown>;
  };
} = {};

const ALREADY_MOUNTED: any = {};

const symModalId = Symbol("ModalId");

const modalCallbacks: ModalCallbacks = {};
const hideModalCallbacks: ModalCallbacks = {};

interface ModalWithId extends React.FC<any> {
  [symModalId]?: string;
}
let idIndex = 1;
const getUid = () => `_modal_${Date.now()}_${idIndex++}`;

let dispatch: React.Dispatch<ModalAction> = () => {
  throw new Error(
    "No dispatch method detected, did you embed your app with NiceModal.Provider?",
  );
};

const getModalId = (modal: string | ModalWithId): string => {
  if (typeof modal === "string") return modal as string;
  if (!modal[symModalId]) {
    modal[symModalId] = getUid();
  }
  return modal[symModalId];
};

export const ModalContext = React.createContext<ModalStore>(initialState);

const ModalIdContext = React.createContext<string | null>(null);

// All registered modals will be rendered in modal placeholder
export const register = <T extends React.FC<any>>(
  id: string,
  comp: T,
  props?: Partial<ModalArgs<T>>,
): void => {
  if (!MODAL_REGISTRY[id]) {
    MODAL_REGISTRY[id] = { comp, props };
  } else {
    MODAL_REGISTRY[id].props = props;
  }
};

function reducer(state: ModalStore = initialState, action: ModalAction) {
  switch (action.type) {
    case MODAL_SHOW_ACTION: {
      const { modalId, args } = action.payload;
      return {
        ...state,
        [modalId]: {
          ...state[modalId],
          id: modalId,
          args,
          visible: !!ALREADY_MOUNTED[modalId],
          delayVisible: !ALREADY_MOUNTED[modalId],
        },
      };
    }
    case MODAL_HIDE_ACTION: {
      const { modalId } = action.payload;
      if (!state[modalId]) return state;
      return {
        ...state,
        [modalId]: {
          ...state[modalId],
          visible: false,
        },
      };
    }
    case MODAL_REMOVE_ACTION: {
      const { modalId } = action.payload;
      const newState = { ...state };
      delete newState[modalId];
      return newState;
    }
    default:
      return state;
  }
}

function show(
  modal: React.FC<any> | string,
  args?: ModalArgs<React.FC<any>> | Record<string, unknown>,
) {
  const modalId = getModalId(modal);

  if (typeof modal !== "string" && !MODAL_REGISTRY[modalId]) {
    register(modalId, modal as React.FC);
  }

  dispatch(showModalAction(modalId, args));

  if (!modalCallbacks[modalId]) {
    let theResolve!: (args?: unknown) => void;
    let theReject!: (args?: unknown) => void;
    const promise = new Promise((resolve, reject) => {
      theResolve = resolve;
      theReject = reject;
    });
    modalCallbacks[modalId] = {
      resolve: theResolve,
      reject: theReject,
      promise,
    };
  }
  return modalCallbacks[modalId].promise;
}

function hide(modal: React.FC<any> | string) {
  const modalId = getModalId(modal);
  dispatch(hideModalAction(modalId));

  delete modalCallbacks[modalId];
  if (!hideModalCallbacks[modalId]) {
    let theResolve!: (args?: unknown) => void;
    let theReject!: (args?: unknown) => void;
    const promise = new Promise((resolve, reject) => {
      theResolve = resolve;
      theReject = reject;
    });
    hideModalCallbacks[modalId] = {
      resolve: theResolve,
      reject: theReject,
      promise,
    };
  }
  return hideModalCallbacks[modalId].promise;
}

function remove(modal: React.FC<any> | string) {
  const modalId = getModalId(modal);
  dispatch(removeModalAction(modalId));
  delete modalCallbacks[modalId];
  delete hideModalCallbacks[modalId];
}

const setFlags = (modalId: string, flags: Record<string, unknown>): void => {
  dispatch(setModalFlagsAction(modalId, flags));
};

export function useModal(modal?: any, args?: any): ModalHandler {
  const modals = useContext(ModalContext);
  const contextModalId = useContext(ModalIdContext);

  let modalId: string | null = null;
  const isUseComponent = modal && typeof modal !== "string";
  if (!modal) {
    modalId = contextModalId;
  } else {
    modalId = getModalId(modal);
  }

  // Only if contextModalId doesn't exist
  if (!modalId) throw new Error("No modal id found in NiceModal.useModal.");

  const mid = modalId as string;
  const modalInfo = modals[mid];

  // If use a component directly, register it.
  useEffect(() => {
    if (isUseComponent && !MODAL_REGISTRY[mid]) {
      register(mid, modal as React.FC, args);
    }
  }, [isUseComponent, mid, modal, args]);

  const showCallback = useCallback(
    (args?: Record<string, unknown>) => show(mid, args),
    [mid],
  );
  const hideCallback = useCallback(() => hide(mid), [mid]);
  const removeCallback = useCallback(() => remove(mid), [mid]);

  const resolveCallback = useCallback(
    (args?: unknown) => {
      modalCallbacks[mid]?.resolve(args);
      delete modalCallbacks[mid];
    },
    [mid],
  );
  const rejectCallback = useCallback(
    (args?: unknown) => {
      modalCallbacks[mid]?.reject(args);
      delete modalCallbacks[mid];
    },
    [mid],
  );
  const resolveHide = useCallback(
    (args?: unknown) => {
      hideModalCallbacks[mid]?.resolve(args);
      delete hideModalCallbacks[mid];
    },
    [mid],
  );

  return useMemo(
    () => ({
      id: mid,
      args: modalInfo?.args,
      visible: !!modalInfo?.visible,
      keepMounted: !!modalInfo?.keepMounted,
      show: showCallback,
      hide: hideCallback,
      remove: removeCallback,
      resolve: resolveCallback,
      reject: rejectCallback,
      resolveHide,
    }),
    [
      mid,
      modalInfo?.args,
      modalInfo?.visible,
      modalInfo?.keepMounted,
      showCallback,
      hideCallback,
      removeCallback,
      resolveCallback,
      rejectCallback,
      resolveHide,
    ],
  );
}

const ModalPlaceholder: FC = () => {
  const modals = useContext(ModalContext);
  const visibleModalIds = Object.keys(modals).filter((id) => !!modals[id]);

  visibleModalIds.forEach((id) => {
    if (!MODAL_REGISTRY[id] && !ALREADY_MOUNTED[id]) {
      console.warn(
        `No modal found for id: ${id}. Please check the id or if it is registered or declared via JSX.`,
      );
      return;
    }
  });

  const toRender = visibleModalIds
    .filter((id) => MODAL_REGISTRY[id])
    .map((id) => ({
      id,
      ...MODAL_REGISTRY[id],
    }));

  return (
    <>
      {createPortal(
        toRender.map((t) => {
          if (!t.comp) {
            console.warn(`Component for modal id ${t.id} is undefined.`);
            return null;
          }
          return <t.comp key={t.id} id={t.id} {...t.props} />;
        }),
        document.body,
      )}
    </>
  );
};
const Provider: FC<PropsWithChildren> = ({ children }) => {
  const arr = useReducer(reducer, initialState);
  const modals = arr[0];
  dispatch = arr[1];
  return (
    <ModalContext.Provider value={modals}>
      {children}
      <ModalPlaceholder />
    </ModalContext.Provider>
  );
};

export const create = <P extends object>(
  Comp: React.ComponentType<P>,
): React.FC<P & ModalHocProps> => {
  return ({ defaultVisible, keepMounted, id, ...props }) => {
    const { args, show } = useModal(id);

    // If there's modal state, then should mount it.
    const modals = useContext(ModalContext);
    const shouldMount = !!modals[id];

    useEffect(() => {
      // If defaultVisible, show it after mounted.
      if (defaultVisible) {
        show();
      }

      ALREADY_MOUNTED[id] = true;

      return () => {
        delete ALREADY_MOUNTED[id];
      };
    }, [id, show, defaultVisible]);

    useEffect(() => {
      if (keepMounted) setFlags(id, { keepMounted: true });
    }, [id, keepMounted]);

    const delayVisible = modals[id]?.delayVisible;
    // If modal.show is called
    //  1. If modal was mounted, should make it visible directly
    //  2. If modal has not been mounted, should mount it first, then make it visible
    useEffect(() => {
      if (delayVisible) {
        // delayVisible: false => true, it means the modal.show() is called, should show it.
        show(args);
      }
    }, [delayVisible, args, show]);

    if (!shouldMount) return null;
    return (
      <ModalIdContext.Provider value={id}>
        <Comp {...(props as unknown as P)} {...args} />
      </ModalIdContext.Provider>
    );
  };
};

/**
 * Unregister a modal.
 * @param id - The id of the modal.
 */
const unregister = (id: string): void => {
  delete MODAL_REGISTRY[id];
};

const Modal_MG = {
  Provider,
  useModal,
  register,
  unregister,
  show,
  hide,
  remove,
  create,
};
export default Modal_MG;
