import { api } from "@ella/web/queryClient";
import { useAccountStore } from "../stores/account-store";

export const verifyInviteToken = async (token: string, orgId: string) => {
  const response = await api.get(`/v1/auth/invite/verify`, {
    params: { token, orgId },
  });
  const apiData = response.data;
  if (apiData.success) {
    useAccountStore.setState({
      email: apiData.data.email,
      firstName: apiData.data.firstName,
      lastName: apiData.data.lastName,
      token: token,
    });
  }

  return response.data;
};
