import { createFileRoute } from "@tanstack/react-router";

import LoginContainer from "@ella/web/modules/auth/containers/login-container";

export const Route = createFileRoute("/auth/login")({
  validateSearch: (search: any) => {
    // validate and parse the search params into a typed state
    return {
      redirect: search.redirect as string,
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  return <LoginContainer />;
}
