import CompletedContainer from "@ella/web/modules/auth/containers/completed-container";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/auth/_private/completed")({
  component: RouteComponent,
});

function RouteComponent() {
  return <CompletedContainer />;
}
