/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as DomainImport } from './routes/domain'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AuthInviteImport } from './routes/auth/invite'
import { Route as AuthPrivateImport } from './routes/auth/_private'
import { Route as AuthenticatedStaffsImport } from './routes/_authenticated/staffs'
import { Route as AuthPrivateCompletedImport } from './routes/auth/_private.completed'

// Create Virtual Routes

const AuthImport = createFileRoute('/auth')()
const AuthInvalidTokenLazyImport = createFileRoute('/auth/invalid-token')()
const AuthenticatedStudentsGuardiansLazyImport = createFileRoute(
  '/_authenticated/students-guardians',
)()
const AuthPrivateSetupLazyImport = createFileRoute('/auth/_private/setup')()
const AuthPrivateSetPasswordLazyImport = createFileRoute(
  '/auth/_private/set-password',
)()

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const DomainRoute = DomainImport.update({
  id: '/domain',
  path: '/domain',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthInvalidTokenLazyRoute = AuthInvalidTokenLazyImport.update({
  id: '/invalid-token',
  path: '/invalid-token',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/auth/invalid-token.lazy').then((d) => d.Route),
)

const AuthenticatedStudentsGuardiansLazyRoute =
  AuthenticatedStudentsGuardiansLazyImport.update({
    id: '/students-guardians',
    path: '/students-guardians',
    getParentRoute: () => AuthenticatedRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/students-guardians.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthInviteRoute = AuthInviteImport.update({
  id: '/invite',
  path: '/invite',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPrivateRoute = AuthPrivateImport.update({
  id: '/_private',
  getParentRoute: () => AuthRoute,
} as any)

const AuthenticatedStaffsRoute = AuthenticatedStaffsImport.update({
  id: '/staffs',
  path: '/staffs',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthPrivateSetupLazyRoute = AuthPrivateSetupLazyImport.update({
  id: '/setup',
  path: '/setup',
  getParentRoute: () => AuthPrivateRoute,
} as any).lazy(() =>
  import('./routes/auth/_private.setup.lazy').then((d) => d.Route),
)

const AuthPrivateSetPasswordLazyRoute = AuthPrivateSetPasswordLazyImport.update(
  {
    id: '/set-password',
    path: '/set-password',
    getParentRoute: () => AuthPrivateRoute,
  } as any,
).lazy(() =>
  import('./routes/auth/_private.set-password.lazy').then((d) => d.Route),
)

const AuthPrivateCompletedRoute = AuthPrivateCompletedImport.update({
  id: '/completed',
  path: '/completed',
  getParentRoute: () => AuthPrivateRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/domain': {
      id: '/domain'
      path: '/domain'
      fullPath: '/domain'
      preLoaderRoute: typeof DomainImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/staffs': {
      id: '/_authenticated/staffs'
      path: '/staffs'
      fullPath: '/staffs'
      preLoaderRoute: typeof AuthenticatedStaffsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/auth/_private': {
      id: '/auth/_private'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthPrivateImport
      parentRoute: typeof AuthRoute
    }
    '/auth/invite': {
      id: '/auth/invite'
      path: '/invite'
      fullPath: '/auth/invite'
      preLoaderRoute: typeof AuthInviteImport
      parentRoute: typeof AuthImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_authenticated/students-guardians': {
      id: '/_authenticated/students-guardians'
      path: '/students-guardians'
      fullPath: '/students-guardians'
      preLoaderRoute: typeof AuthenticatedStudentsGuardiansLazyImport
      parentRoute: typeof AuthenticatedImport
    }
    '/auth/invalid-token': {
      id: '/auth/invalid-token'
      path: '/invalid-token'
      fullPath: '/auth/invalid-token'
      preLoaderRoute: typeof AuthInvalidTokenLazyImport
      parentRoute: typeof AuthImport
    }
    '/auth/_private/completed': {
      id: '/auth/_private/completed'
      path: '/completed'
      fullPath: '/auth/completed'
      preLoaderRoute: typeof AuthPrivateCompletedImport
      parentRoute: typeof AuthPrivateImport
    }
    '/auth/_private/set-password': {
      id: '/auth/_private/set-password'
      path: '/set-password'
      fullPath: '/auth/set-password'
      preLoaderRoute: typeof AuthPrivateSetPasswordLazyImport
      parentRoute: typeof AuthPrivateImport
    }
    '/auth/_private/setup': {
      id: '/auth/_private/setup'
      path: '/setup'
      fullPath: '/auth/setup'
      preLoaderRoute: typeof AuthPrivateSetupLazyImport
      parentRoute: typeof AuthPrivateImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedStaffsRoute: typeof AuthenticatedStaffsRoute
  AuthenticatedStudentsGuardiansLazyRoute: typeof AuthenticatedStudentsGuardiansLazyRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedStaffsRoute: AuthenticatedStaffsRoute,
  AuthenticatedStudentsGuardiansLazyRoute:
    AuthenticatedStudentsGuardiansLazyRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface AuthPrivateRouteChildren {
  AuthPrivateCompletedRoute: typeof AuthPrivateCompletedRoute
  AuthPrivateSetPasswordLazyRoute: typeof AuthPrivateSetPasswordLazyRoute
  AuthPrivateSetupLazyRoute: typeof AuthPrivateSetupLazyRoute
}

const AuthPrivateRouteChildren: AuthPrivateRouteChildren = {
  AuthPrivateCompletedRoute: AuthPrivateCompletedRoute,
  AuthPrivateSetPasswordLazyRoute: AuthPrivateSetPasswordLazyRoute,
  AuthPrivateSetupLazyRoute: AuthPrivateSetupLazyRoute,
}

const AuthPrivateRouteWithChildren = AuthPrivateRoute._addFileChildren(
  AuthPrivateRouteChildren,
)

interface AuthRouteChildren {
  AuthPrivateRoute: typeof AuthPrivateRouteWithChildren
  AuthInviteRoute: typeof AuthInviteRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthInvalidTokenLazyRoute: typeof AuthInvalidTokenLazyRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthPrivateRoute: AuthPrivateRouteWithChildren,
  AuthInviteRoute: AuthInviteRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthInvalidTokenLazyRoute: AuthInvalidTokenLazyRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/domain': typeof DomainRoute
  '/staffs': typeof AuthenticatedStaffsRoute
  '/auth': typeof AuthPrivateRouteWithChildren
  '/auth/invite': typeof AuthInviteRoute
  '/auth/login': typeof AuthLoginRoute
  '/students-guardians': typeof AuthenticatedStudentsGuardiansLazyRoute
  '/auth/invalid-token': typeof AuthInvalidTokenLazyRoute
  '/auth/completed': typeof AuthPrivateCompletedRoute
  '/auth/set-password': typeof AuthPrivateSetPasswordLazyRoute
  '/auth/setup': typeof AuthPrivateSetupLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/domain': typeof DomainRoute
  '/staffs': typeof AuthenticatedStaffsRoute
  '/auth': typeof AuthPrivateRouteWithChildren
  '/auth/invite': typeof AuthInviteRoute
  '/auth/login': typeof AuthLoginRoute
  '/students-guardians': typeof AuthenticatedStudentsGuardiansLazyRoute
  '/auth/invalid-token': typeof AuthInvalidTokenLazyRoute
  '/auth/completed': typeof AuthPrivateCompletedRoute
  '/auth/set-password': typeof AuthPrivateSetPasswordLazyRoute
  '/auth/setup': typeof AuthPrivateSetupLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/domain': typeof DomainRoute
  '/_authenticated/staffs': typeof AuthenticatedStaffsRoute
  '/auth': typeof AuthRouteWithChildren
  '/auth/_private': typeof AuthPrivateRouteWithChildren
  '/auth/invite': typeof AuthInviteRoute
  '/auth/login': typeof AuthLoginRoute
  '/_authenticated/students-guardians': typeof AuthenticatedStudentsGuardiansLazyRoute
  '/auth/invalid-token': typeof AuthInvalidTokenLazyRoute
  '/auth/_private/completed': typeof AuthPrivateCompletedRoute
  '/auth/_private/set-password': typeof AuthPrivateSetPasswordLazyRoute
  '/auth/_private/setup': typeof AuthPrivateSetupLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/domain'
    | '/staffs'
    | '/auth'
    | '/auth/invite'
    | '/auth/login'
    | '/students-guardians'
    | '/auth/invalid-token'
    | '/auth/completed'
    | '/auth/set-password'
    | '/auth/setup'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/domain'
    | '/staffs'
    | '/auth'
    | '/auth/invite'
    | '/auth/login'
    | '/students-guardians'
    | '/auth/invalid-token'
    | '/auth/completed'
    | '/auth/set-password'
    | '/auth/setup'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/domain'
    | '/_authenticated/staffs'
    | '/auth'
    | '/auth/_private'
    | '/auth/invite'
    | '/auth/login'
    | '/_authenticated/students-guardians'
    | '/auth/invalid-token'
    | '/auth/_private/completed'
    | '/auth/_private/set-password'
    | '/auth/_private/setup'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  DomainRoute: typeof DomainRoute
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  DomainRoute: DomainRoute,
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/domain",
        "/auth"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/staffs",
        "/_authenticated/students-guardians"
      ]
    },
    "/domain": {
      "filePath": "domain.tsx"
    },
    "/_authenticated/staffs": {
      "filePath": "_authenticated/staffs.tsx",
      "parent": "/_authenticated"
    },
    "/auth": {
      "filePath": "auth",
      "children": [
        "/auth/_private",
        "/auth/invite",
        "/auth/login",
        "/auth/invalid-token"
      ]
    },
    "/auth/_private": {
      "filePath": "auth/_private.tsx",
      "parent": "/auth",
      "children": [
        "/auth/_private/completed",
        "/auth/_private/set-password",
        "/auth/_private/setup"
      ]
    },
    "/auth/invite": {
      "filePath": "auth/invite.tsx",
      "parent": "/auth"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx",
      "parent": "/auth"
    },
    "/_authenticated/students-guardians": {
      "filePath": "_authenticated/students-guardians.lazy.tsx",
      "parent": "/_authenticated"
    },
    "/auth/invalid-token": {
      "filePath": "auth/invalid-token.lazy.tsx",
      "parent": "/auth"
    },
    "/auth/_private/completed": {
      "filePath": "auth/_private.completed.tsx",
      "parent": "/auth/_private"
    },
    "/auth/_private/set-password": {
      "filePath": "auth/_private.set-password.lazy.tsx",
      "parent": "/auth/_private"
    },
    "/auth/_private/setup": {
      "filePath": "auth/_private.setup.lazy.tsx",
      "parent": "/auth/_private"
    }
  }
}
ROUTE_MANIFEST_END */
