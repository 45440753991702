import { useState, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { api } from "../queryClient";
import useOrganization from "./useOrganization";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AuthenticationStore {
  user: any;
  refreshToken: string;
  accessToken: string;
  organization: any;
  isAuthenticated: boolean;
  setAuthenticatedUser(user: any): void;
  setToken(accessToken: string, refreshToken: string): void;
  logout(): void;
}

export const useAuthenticationStore = create<AuthenticationStore>()(
  persist(
    (set) => {
      return {
        user: null,
        organization: null,
        isAuthenticated: false,
        accessToken: "",
        refreshToken: "",
        setAuthenticatedUser: (user) => {
          set({ user, isAuthenticated: !!user });
        },
        setToken: (accessToken, refreshToken) => {
          if (!refreshToken) {
            return set({
              user: null,
              isAuthenticated: false,
              accessToken: "",
              refreshToken: "",
            });
          }
          set({ accessToken, refreshToken });
        },
        logout: () => {
          set({
            user: null,
            isAuthenticated: false,
            accessToken: "",
            refreshToken: "",
          });
        },
      };
    },
    { name: "authentication" }
  )
);
// Types for authentication

interface LoginCredentials {
  email: string;
  password: string;
}

interface UseAuthenticationOptions {
  onLoginSuccess?: () => void;
  onLoginError?: (error: any) => void;
  onLogoutSuccess?: () => void;
}

/**
 * Hook to manage authentication state and operations
 * @param options - Configuration options
 * @returns Authentication state and functions
 */
export const useAuthentication = (options?: UseAuthenticationOptions) => {
  const authenticationStore = useAuthenticationStore();
  const isAuthenticated = useAuthenticationStore(
    (state) => state.isAuthenticated
  );

  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);
  const { organization, isLoading } = useOrganization();
  // Fetch current authenticated user
  const {
    data: user,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["auth", "user"],
    queryFn: async () => {
      try {
        const response = await api.get("/v1/auth/me");
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(
            error.response.data.message || "Failed to authenticate user"
          );
        }
        throw error;
      }
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Login mutation
  const loginMutation = useMutation({
    mutationFn: async (credentials: LoginCredentials) => {
      try {
        const response = await api.post("/v1/auth/session", {
          ...credentials,
          organizationId: organization?.id,
        });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(error.response.data.message || "Login failed");
        }
        throw error;
      }
    },
    onSuccess: (data) => {
      if (data?.success) {
        authenticationStore.setAuthenticatedUser(data.data.user);
        authenticationStore.setToken(
          data.data.accessToken,
          data.data.refreshToken
        );
        // Invalidate user query to refetch user data
        queryClient.invalidateQueries({ queryKey: ["auth", "user"] });
        options?.onLoginSuccess?.();
      } else {
        options?.onLoginError?.(data?.errors);
        setError(new Error(data?.message || "Login failed"));
      }
    },
    onError: (error: Error) => {
      setError(error);
      options?.onLoginError?.(error);
    },
  });

  // Logout function
  const logout = useCallback(async () => {
    try {
      await api.post("/v1/auth/logout");
      // Clear user data from cache
      queryClient.setQueryData(["auth", "user"], null);
      // Invalidate all queries to force refetch
      queryClient.invalidateQueries();
      options?.onLogoutSuccess?.();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }, [queryClient, options]);

  return {
    user,
    organization,
    isAuthenticated: isAuthenticated,
    isLoading: loginMutation.isPending || isLoading,
    isError,
    error,
    login: loginMutation.mutate,
    loginAsync: loginMutation.mutateAsync,
    logout,
    refetchUser: refetch,
    updateToken: authenticationStore.setToken,
    updateUser: authenticationStore.setAuthenticatedUser,
  };
};

export default useAuthentication;
